/*
  ~ Copyright © Reach Digital (https://www.reachdigital.io/)
  ~ See LICENSE.txt for license details.
  */
import { LitElement, html } from 'lit-element'
import '@polymer/paper-item/paper-item.js'
import { M2NavigationMixin } from '@reachdigital/m2-navigation/m2-navigation-mixin'

class AviVerticalNavigation extends M2NavigationMixin(LitElement) {
  render() {
    return html`
      <style>
        nav ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }

        nav ul li {
          border-bottom: 1px solid var(--base-separator);
        }

        nav ul li a:hover,
        nav ul li.menu-item-active a {
          font-weight: bold;
        }

        nav ul li.menu-item-active a:after {
          content: '»';
        }

        nav ul li a {
          display: block;
          padding: 15px 0;
          color: var(--secondary);
          text-decoration: none;
        }

        .heading {
          color: var(--secondary);
        }

        ::slotted(h3[slot='heading']) {
          margin-bottom: 0 !important;
        }

        ::slotted(img[slot='wave']) {
          max-width: 75px !important;
          position: relative;
          top: -5px;
        }
      </style>

      <nav class="menu">
        <div class="heading">
          <slot name="heading"></slot>
        </div>

        <slot name="wave"></slot>
        <slot hidden @slotchange=${this.handleSlotchange}></slot>
        ${this.renderNavigationTree(this.items, 0)}
      </nav>
    `
  }

  renderNavigationTree(items, level) {
    return html`
      <ul class="top-menu">
        ${items.map(
          (item) =>
            html`
              <li class="item ${item.class} level-${level}">
                <a
                  href="${item.link}"
                  title="${item.title}"
                  class="title ${item.children?.length > 0 ? 'haschild-link' : 'inner-link'}"
                >
                  <span>${item.title}</span>
                </a>
              </li>
            `,
        )}
      </ul>
    `
  }
}

customElements.define('avi-vertical-navigation', AviVerticalNavigation)
