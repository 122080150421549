/*
 * Copyright © Reach Digital (https://www.reachdigital.io/)
 * See LICENSE.txt for license details.
 */

import { html, LitElement } from 'lit-element'
import '@reachdigital/m2-container/m2-container'
import { isMobile } from '@reachdigital/utils/is-mobile'
import '@reachdigital/shop-container/shop-container'
import { afterNextRender } from '@polymer/polymer/lib/utils/render-status'

class AviCatalogProductView extends LitElement {
  static get properties() {
    return {
      isMobile: {
        type: Boolean,
      },
      galleryHeight: {
        type: Number,
      },
      hasSticker: {
        type: Boolean,
        attribute: 'has-sticker',
        reflect: true,
      },
    }
  }

  connectedCallback() {
    super.connectedCallback()
    this.isMobile = isMobile((event) => {
      this.isMobile = event.matches
    })
  }

  firstUpdated(_changedProperties) {
    super.firstUpdated(_changedProperties)

    afterNextRender(this, () => {
      this.__intersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => this.handleIntersection(entry))
        },
        { rootMargin: '10000px 0px 100px 0px' },
      )
      this.__intersectionObserver.observe(
        this.shadowRoot.querySelector('.page-bottom-detection-pixel'),
      )
    })
  }

  handleIntersection(entry) {
    this.dispatchEvent(
      new CustomEvent('catalog-product-view__bottom-intersection-changed', {
        detail: { isIntersecting: entry.isIntersecting },
        bubbles: true,
        composed: true,
      }),
    )
  }

  render() {
    return html`
      <style>
        :host {
          display: block;
          background: white;
          /*overflow:hidden;*/
          /*@todo fix overflow issue without overflow hidden due to position sticky of child */
        }

        ::slotted([slot='configurator-title']) {
          margin-bottom: 32px !important;
          font-weight: var(--font-weight-headings) !important;
        }

        ::slotted(h1) {
          color: var(--secondary) !important;
        }

        .top-wrapper {
          display: flex;
        }
        .top-wrapper > m2-container {
          width: 100%;
        }
        .mobile-media-wrapper {
          position: relative;
        }
        :host([has-sticker]) .sticker {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 2;
          padding: 8px 20px;
          color: var(--base);
          font-size: 13px;
          background: var(--secondary);
        }

        @media (min-width: 768px) {
          ::slotted([slot='media-gallery']) {
            margin-bottom: var(--spacing-lg);
          }
        }
        .top {
          display: flex;
          width: 100%;
        }
        @media (max-width: 767px) {
          .top {
            flex-wrap: wrap;
            margin-left: 0;
            margin-right: 0;
            padding-top: 0;
          }
        }
        .top > * {
          box-sizing: border-box;
          padding-bottom: var(--spacing-lg);
          --m2-media-gallery__top: var(--spacing-xl);
        }
        .top > .media {
          padding-right: calc(var(--spacing-lg) / 2);
        }
        .top > .data {
          padding-left: calc(var(--spacing-lg) / 2);
        }
        @media (min-width: 768px) and (max-width: 991px) {
          .top > .media {
            padding-right: calc(var(--spacing-md) / 2);
          }
          .top > .data {
            padding-left: calc(var(--spacing-md) / 2);
          }
        }
        @media (max-width: 767px) {
          .top > .media {
            z-index: 2;
            padding-left: var(--m2-container-padding);
            padding-right: var(--m2-container-padding);
            padding-bottom: calc(var(--spacing-md) * 0.5);
            margin-left: calc(var(--m2-container-padding) * -1);
            margin-right: calc(var(--m2-container-padding) * -1);
            max-width: none;
          }
          .top > .data {
            padding-left: var(--m2-container-padding);
            padding-right: var(--m2-container-padding);
          }
        }
        .media {
          flex: 0 0 59%;
        }
        .media-inner {
          position: relative;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          .media {
            flex: 1 0 42%;
          }
        }
        .data {
          flex: 0 0 41%;
        }
        @media (max-width: 767px) {
          .top > * {
            flex: 1 1 100%;
            max-width: 100%;
          }

          .data {
            margin: 0 calc(var(--m2-container-padding) * -1) 20px;
            max-width: none;
            width: auto;
            padding: var(--m2-container-padding);
            background: white;
          }
        }

        .short-description-wrapper {
          margin-top: var(--spacing-xs);
          margin-bottom: var(--spacing-xs);
        }

        .short-description-wrapper ::slotted(*) {
          display: inline;
        }

        @media (min-width: 768px) {
          shop-container [slot='left'] {
            padding-right: var(--spacing-xl);
          }
          shop-container {
            overflow: hidden;
          }
          shop-container [slot='right'] {
            height: 100%;
          }
        }
        @media (max-width: 767px) {
          shop-container [slot='right'] {
            margin-left: calc(var(--m2-container-padding) * -1);
            margin-right: calc(var(--m2-container-padding) * -1);
            margin-top: var(--spacing-lg);
          }
        }

        ::slotted([slot='contact-phone']) {
          margin-top: var(--spacing-md);
        }

        :host([product-type-id='simple']) ::slotted([slot='contact-phone']) {
          margin-bottom: var(--spacing-md);
        }
      </style>
      <div class="top-wrapper">
        <m2-container>
          <slot name="title"></slot>
          <slot name="review-summary"></slot>
          <div class="top">
            ${this.isMobile
              ? html`
                  <div class="mobile-media-wrapper">
                    <slot name="media-gallery"></slot>
                    <div class="sticker">
                      <slot name="sticker"></slot>
                    </div>
                  </div>
                `
              : html`
                  <div class="media">
                    <div class="media-inner">
                      <div class="sticker">
                        <slot name="sticker"></slot>
                      </div>
                      <slot name="media-gallery"></slot>
                    </div>

                    <slot name="reviews"></slot>
                  </div>
                `}
            <div class="data">
              <slot name="configurator-title"></slot>
              <slot name="short-description"></slot>
              <slot name="add-to-cart"></slot>
              <slot name="contact-phone"></slot>
              ${this.isMobile ? html`<slot name="reviews"></slot>` : ''}
            </div>
          </div>
        </m2-container>
      </div>

      <slot name="content"></slot>
      <div class="page-bottom-detection-pixel"></div>
    `
  }
}

customElements.define('avi-catalog-product-view', AviCatalogProductView)
